/// @deprecated
/// DO NOT EXTEND THIS FILE, MOVE TO PRIMEFLEX

// overrides the built-in bootstrap breakpoints

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1280px,
    xl: 1600px,
    xxl: 1920px
);

// Breaks to a new flex row
.break,
.break-sm,
.break-md,
.break-lg,
.break-xl,
.break-xxl {
    flex-basis: 100%;
    height: 0;
}

// Breaks to a new flex column
.break-column,
.break-column-sm,
.break-column-md,
.break-column-lg .break-column-xl,
.break-column-xxl {
    flex-basis: 100%;
    width: 0;
}

@media screen and (min-width: map-get($grid-breakpoints, sm)) {
    .break-sm,
    .break-column-sm {
        display: none;
    }
}

@media screen and (min-width: map-get($grid-breakpoints, md)) {
    .break-md,
    .break-column-md {
        display: none;
    }
}

@media screen and (min-width: map-get($grid-breakpoints, lg)) {
    .break-lg,
    .break-column-lg {
        display: none;
    }
}

@media screen and (min-width: map-get($grid-breakpoints, xl)) {
    .break-xl,
    .break-column-xl {
        display: none;
    }
}

@media screen and (min-width: map-get($grid-breakpoints, xxl)) {
    .break-xxl,
    .break-column-xxl {
        display: none;
    }
}
