/// @deprecated
/// DO NOT EXTEND THIS FILE, MOVE TO PRIMENG COMPONENTS / GLOBAL THEME

@import 'design-system';

$form-control-disabled-background: #e9ecef;
$form-control-border-color: #d5d5d5;

label {
    .icon--info-outline::before {
        color: $maritech-action;
        display: inline-block;
        font-size: 20px;
        margin: -$guttering-small 0;
    }
}

.row,
.form-row {
    .form-group {
        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}

.form-group {
    border-color: $maritech-action;
    line-height: 1;
    margin-bottom: 0;
    padding: $guttering-small;

    label {
        color: $font-colour-secondary;
        font-size: $font-size-caption;
        line-height: 1;
        margin-bottom: $guttering-tiny;
    }

    .form-control {
        &:focus-within:not(.ng-invalid):not(.ngrx-forms-invalid) {
            border: $border-width solid $maritech-action;
        }
    }

    &.required > label::before {
        color: $maritech-caution;
        content: '* ';
    }

    .form-control:not(textarea),
    select.form-control:not([size]):not([multiple]) {
        height: $input-element-height-condensed;
        min-height: $input-element-height-condensed;
    }
}

.form-control {
    border-color: $input-field-border;
    border-radius: 0;
    box-shadow: none;
    color: $font-colour-primary;
    font-size: $font-size-body-1;
    padding-left: $guttering-small;
    padding-right: $guttering-small;

    &.ops-warning--current {
        border-color: $maritech-orange !important;
    }

    &.ng-invalid.ng-touched,
    .ngrx-form-group-touched &.ngrx-forms-invalid,
    &.ngrx-forms-invalid.ngrx-forms-touched {
        border-color: $maritech-warn;
    }
}

.ngrx-form-group-touched .ngrx-forms-invalid,
.ngrx-forms-invalid.ngrx-forms-touched {
    ::ng-deep .ng-select-container {
        border-color: $maritech-warn;
    }
}

.ops-warning--current ::ng-deep .ng-select-container {
    border-color: $maritech-orange !important;
}

ops-date-input {
    &.ops-warning--current {
        border-color: $maritech-orange !important;
    }
}

:host-context(.invalid-on-save) {
    .form-control {
        &.ng-invalid,
        &.ngrx-forms-invalid {
            border-color: $maritech-warn;
        }
    }

    .ngrx-forms-invalid ::ng-deep .ng-select-container {
        border-color: $maritech-warn;
    }
}

.text-danger {
    color: $maritech-warning;
}
