/// @deprecated
/// DO NOT EXTEND THIS FILE, MOVE TO PRIMENG COMPONENTS / GLOBAL THEME

@import 'design-system';

/// Renamed to avoid conflict with design system (should not be used in new code)
/// @deprecated
%ops-action-button {
    button:not(.btn) {
        background-color: inherit;
        border-width: 0;
        cursor: pointer;
        outline: none;

        &:disabled {
            cursor: auto;
        }

        &.btn-add {
            &:focus:not(:disabled),
            &:hover:not(:disabled) {
                color: $maritech-action;
            }
        }

        &.btn-clone {
            &:focus:not(:disabled),
            &:hover:not(:disabled) {
                color: $maritech-action;
            }
        }

        &.btn-delete {
            &:focus:not(:disabled),
            &:hover:not(:disabled) {
                color: $maritech-warning;
            }
        }

        &.btn-inverted {
            color: $font-color-selected;

            &:disabled {
                color: $font-color-tertiary;
            }
        }
    }
}

.more-menu {
    font-family: HelveticaNeue, sans-serif;

    .more-menu-item {
        border-radius: 0;
        cursor: pointer;
        padding: 0 $guttering-small;

        span {
            padding: 0 $guttering-small;
        }
    }

    .more-menu-item:hover {
        background-color: $maritech-action;
        color: $maritech-base;
    }

    button:hover:not(:disabled) {
        color: $maritech-action;
    }
}
