@import 'design-system';
@import 'zindex';

.nav-tabs {
    background-color: $maritech-base;
    border-bottom: $border-width solid $section-heading-text;
    border-top: $border-width solid $section-heading-text;
    font-size: $font-size-title;
    font-weight: bold;
    margin-top: $guttering-medium;

    .nav-link {
        border: 0 none;
        color: $font-colour-primary;
        height: 40px;
        padding-top: $guttering-micro;

        &.active {
            background-color: inherit;
            border-bottom: $border-width-heavy solid $maritech-action;
            color: $maritech-action;
        }

        .wrapped-title {
            display: inline-block;
            white-space: nowrap;
        }
    }

    .validation-indicator {
        display: none;
        margin-left: -32px;
        margin-top: -2px;
        position: absolute;

        &.invalid.touched {
            display: block;
        }
    }
}

.tabs-sticky .nav-tabs {
    position: sticky;
    top: 0;
    // This is required to show the sticky tabs behind the datepicker dropdown, ideally we'd increase the zindex of
    // the tabs to sticky when stuck
    z-index: $zindex-dropdown - 10;
}

.tab-content {
    padding: $guttering-medium;
}

.tab-pane {
    @extend %content-fadein;
}

:host-context(.invalid-on-save) {
    .nav-tabs .validation-indicator.invalid {
        display: block;
    }
}

.icon--warning {
    color: $maritech-warn;
    font-size: $font-size-caption;
}

