@import 'design-system';

.ops-ltc-card {
    background-color: $maritech-card-background;
    border-radius: 4px;
    box-shadow: 2px 2px 4px $maritech-border-lighter;

    .ops-ltc-card-header {
        align-items: center;
        border-bottom: 1px solid $maritech-border-light;
        display: flex;
        height: 40px;
        justify-content: space-between;
        padding: $guttering-tiny $guttering-small;
    }

    .ops-ltc-card-header-title {
        color: $font-colour-primary;
        font-family: $font-family-title;
        font-size: $font-size-subheader;
        font-weight: 600;
    }
}

.ops-ltc-period-positive {
    color: $maritech-positive !important;
}

.ops-ltc-period-negative {
    color: $maritech-warn !important;
}

.ops-ltc-period-neutral {
    color: $font-colour-primary !important;
}

.ops-ltc-header-container {
    border-bottom: 1px solid $maritech-border-light;
    height: 56px;
}

.ops-ltc-calculation-name {
    color: $font-colour-primary;
    font-family: $font-family-title;
    font-size: $font-size-subheader;
    font-weight: 500;
    padding: $guttering-medium $guttering-small;
}

.ops-ltc-loading-panel {
    align-items: center;
    display: flex;
    flex: auto;
    justify-content: center;
}

.ops-ltc-shell-container {
    display: flex;
    flex: auto;
    flex-direction: column;
}

.ops-ltc-imported {
    opacity: 0.5;
    pointer-events: none;
}

.ops-ltc-associated-cargoes-container {
    display: flex;
    flex-wrap: wrap;

    .ops-ltc-card {
        cursor: pointer;
        height: 80px;
        margin: $guttering-medium $guttering-medium 0 0;
        padding: $guttering-small-medium $guttering-medium;
        transition: background-color 300ms ease-in-out;
        will-change: background-color;

        &:hover {
            background-color: $nested-table-heading-background;
        }

        .p-checkbox {
            margin-left: auto;
        }
    }
}

.ops-ltc-result {
    border-right: 1px solid $maritech-border-lighter;
    margin-right: $guttering-large;
    min-width: 215px;
    padding-right: $guttering-large;
    white-space: nowrap;
}

.ops-ltc-result-value {
    display: flex;
    justify-content: space-between;

    .label {
        color: $font-color-secondary;
    }

    .value.negative {
        color: $maritech-warn;
    }
}

ops-associated-cargoes-by-cargo,
ops-associated-cargoes-by-voyage-activity > * {
    @extend %content-fadein;
}
