/// Globally exports the design system to the rest of the app.
/// Import this into global/prime styles and components where variables are required.
///
/// Fills any missing variables that are design system level - not app specific.
@import '@maritech/design-system/src/sass/design-system-light';

$font-family-label: Roboto, Cera, apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-title: Cera, Roboto, apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-menu: HelveticaNeue, sans-serif;
$font-family-icon: ClarksonsIconFont;


$maritech-font-family: $font-family-label;
$maritech-font-family-title: $font-family-title;
$maritech-font-family-menu: $font-family-menu;

// These haven't been updated in the library yet
$maritech-action-darkest: $clarksons-action-darker-3;
$maritech-action-darker: $clarksons-action-darker-2;
$maritech-action-dark: $clarksons-action-darker-1;
$maritech-action-light: $clarksons-action-lighter-1;
$maritech-action-lighter: $clarksons-action-lighter-2;
$maritech-action-lightest: $clarksons-action-lighter-3;

$maritech-border-darkest: $clarksons-border-darkest;
$maritech-border-darker: $clarksons-border-darker;
$maritech-border-dark: $clarksons-border-dark;
$maritech-border-light: $clarksons-border-light;
$maritech-border-lighter: $clarksons-border-lighter;
$maritech-border-lightest: $clarksons-border-lightest;

$maritech-border-lighter: $border-lighter;
$maritech-border-darker: $border-darker;

$maritech-scrollbar-background: #ebebeb;
$maritech-scrollbar-thumb-shadow: #afafaf;
$maritech-positive-light: tint($maritech-positive, $color-light);

$maritech-card-background: #fff;

%content-fadein {
    animation: fadein 1s $easeOutQuart;
}

@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}
