/// @deprecated
/// DO NOT EXTEND THIS FILE, MOVE TO PRIMENG COMPONENTS / GLOBAL THEME

@import 'design-system';
@import 'zindex';

$guttering-tiny-small: ($guttering-small-medium / 2);

.ops-dp-body {
    border-radius: 0;
    margin: 0;
    padding: 0;
    z-index: $zindex-datepicker;
}

ngb-datepicker {
    .ngb-dp-header {
        background-color: inherit !important;
        border-bottom: $border-width solid $maritech-border-lighter !important;
        padding: $guttering-tiny 0 !important;

        ngb-datepicker-navigation {
            height: auto;

            .ngb-dp-navigation-select {
                line-height: 1;

                .custom-select {
                    border: 0 none;
                    font-size: $font-size-caption;
                    padding: 0;
                }
            }

            .ngb-dp-arrow {
                height: auto;

                .ngb-dp-arrow-btn {
                    color: $font-colour-primary;
                    font-size: $font-size-caption;
                    outline: none;
                }
            }
        }
    }

    .ngb-dp-month-name {
        background-color: #fff;
        font-size: $font-size-body-2;
        font-weight: 500;
    }

    .ngb-dp-month {
        font-size: $font-size-caption;
        padding: 0 $guttering-tiny-small $guttering-small;

        .ngb-dp-weekdays {
            background-color: inherit !important;
            border-bottom: 0 none;
            margin-top: 0;
            padding: $guttering-tiny-small $guttering-tiny-small $guttering-tiny;

            .ngb-dp-weekday {
                color: $font-color-primary;
                font-size: $font-size-small-screen;
                font-style: inherit;
                height: 100%;
                line-height: 1;
                width: 42px;
            }
        }

        .ngb-dp-week {
            margin-top: $guttering-tiny;
            padding-left: 0 !important;
            padding-right: 0 !important;

            &:not(.ngb-dp-weekdays) {
                margin-top: $guttering-small;
                padding-bottom: 0;
            }
        }
    }

    .ngb-dp-day {
        cursor: default;
        height: 34px;
        margin-right: 0 !important;
        user-select: none;
        width: (34px + $guttering-small);

        &:focus {
            outline: none;
        }
    }
}
