@import 'design-system';

.p-checkbox {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    outline: 0;
    padding: 0;
    position: relative;
    vertical-align: sub;

    &::before {
        background: #fff;
        border: 2px solid $maritech-grey-darker;
        border-radius: 2px;
        content: '';
        display: block;
        height: 18px;
        width: 18px;
    }

    &:checked::before {
        background: #2196f3;
        border-color: transparent;
    }

    &::after {
        background-color: transparent;
        border: 2px solid transparent;
        border-radius: 50%;
        color: #000;
        content: '';
        display: block;
        font-family: $font-family-icon;
        font-size: 1.2rem;
        font-weight: bold;
        height: 30px;
        left: -6px;
        padding: 5px;
        position: absolute;
        top: -6px;
        width: 30px;
    }

    &:checked::after {
        color: #fff;
        content: '\EA23';
        font-size: 17px;
        line-height: 1.5;
        padding: 1px 4.5px;
    }
}
