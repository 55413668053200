@import 'design-system';
@import 'clarksons-tabs';

$border-radius: 4px;

.ops-coa-nav-tabs {
    border: 0;
    font-family: $font-family-title;
    font-size: 14px;
    font-weight: 500;
    margin-top: $guttering-tiny;
    padding-left: $guttering-medium;

    .nav-item {
        margin: 0 $guttering-micro;
        text-align: center;

        .nav-link {
            border-bottom: 0;
            border-radius: 0;
            line-height: 28px;
            padding: 6px $guttering-small;
            position: relative;
            transition: background-color 0.3s ease;
            will-change: background-color;

            &:hover {
                background-color: $nested-table-heading-background;
            }

            &.active {
                font-weight: 600;

                &::after {
                    background: $maritech-action;
                    transform: scaleY(1);
                }

                &:hover::after {
                    transform: translateX(#{-$guttering-small}) scaleY(1);
                    width: 100%;
                }
            }

            &::after {
                bottom: 0;
                content: ' ';
                height: $border-width-heavy;
                left: $guttering-small;
                position: absolute;
                transition: transform 0.3s ease, width 0.3s ease;
                width: calc(100% - #{$guttering-small * 2});
                will-change: transform, width;
            }
        }
    }
}

.ops-coa-main-content-wrapper,
.ops-lifting-main-content-wrapper {
    width: 100%;

    .ops-coa-main-content,
    .ops-lifting-main-content {
        align-self: center;
        margin: 0 auto;
        padding: $guttering-medium $guttering-large;
        width: 100%;

        .tab-component {
            display: block;
            margin: 0 auto;
            max-width: 1630px;
            min-width: 1220px;
        }
    }
}

.ops-card,
.ops-coa-card {
    background-color: $font-color-selected;
    border-radius: $border-radius;
    box-shadow: 2px 2px 4px $maritech-border-lighter;

    .ops-coa-card-header {
        align-items: center;
        border-bottom: 1px solid $maritech-border-light;
        display: flex;
        height: 40px;
        justify-content: space-between;
        padding: $guttering-tiny $guttering-small;

        ops-action-button {
            button {
                height: 100%;

                span.has-icon.icon--plus {
                    margin-right: 0;
                }
            }
        }
    }

    .ops-coa-card-header-title {
        color: $font-colour-primary;
        font-family: $font-family-title;
        font-size: $font-size-subheader;
        font-weight: 600;
    }

    .ops-card-body,
    .ops-coa-card-body {
        border-radius: 0 0 $border-radius $border-radius;
        display: flex;

        .no-content {
            align-self: center;
            margin: 0 auto;
        }
    }
}

.ops-coa-form {
    background-color: $nested-table-heading-background;
    border-radius: 0 0 $border-radius $border-radius;
    display: flex;
    flex-direction: column;
    padding: $guttering-small $guttering-large $guttering-medium;

    label {
        color: $font-colour-primary !important;
    }

    .form-contents {
        flex-grow: 1;
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        padding-top: $guttering-small-medium;

        ops-action-button {
            margin-right: $guttering-small;
        }

        button.btn-primary,
        ops-action-button:last-of-type {
            margin-right: 0;
        }
    }
}

.ops-coa-audit {
    color: $font-color-secondary;
    font-size: $font-size-caption;
    text-align: end;
}

.ops-coa-status {
    background-color: $font-colour-primary;
    border-radius: 2px;
    color: $maritech-base;
    display: inline-block;
    font-size: $font-size-caption;
    margin: auto 0;
    padding: 0 $guttering-small;

    &.open,
    &.firmed,
    &.fixed {
        background-color: $maritech-positive;
    }

    &.cancelled,
    &.not-started {
        background-color: $maritech-warn;
    }

    &.tentative,
    &.renomination-pending,
    &.planning {
        background-color: $maritech-caution;
    }

    &.sending-for-approval,
    &.pending-approval,
    &.sending-for-approval-failed {
        background-color: $maritech-grey;
    }
}

.ops-coa-summary-header {
    border-right: solid $border-width $font-color-secondary;
    display: flex;
    flex-direction: column;
    font-size: $font-size-subheader;
    justify-content: center;
}

.ops-coa-summary-body {
    display: flex;
    flex-direction: column;
    font-size: $font-size-body-1;
    justify-content: space-around;
}

.ops-coa-loading-panel {
    align-items: center;
    display: flex;
    flex: auto;
    justify-content: center;
}

.ops-table,
.ops-coa-table {
    font-family: $font-family-label;
    margin-bottom: 0;

    thead, 
    tfoot {
        background-color: $maritech-base-dark;

        tr {
            &.ops-coa-table-title-row > th {
                background-color: $font-color-selected;
                color: $font-colour-primary;
                font-family: $font-family-title;
                font-weight: 600;

                &:not(:first-of-type) {
                    border-top: 1px solid $maritech-border-light;
                }
            }

            th,
            td {
                border: 0;
                color: $maritech-grey;
                font-size: $font-size-body-1;
                font-weight: inherit;
                line-height: 0.8;
            }
        }
    }

    tbody > tr,
    tbody > a.link-row {
        background-color: $font-color-selected;
        transition: background-color 0.3s ease;

        &:nth-child(even) {
            background-color: $nested-table-heading-background;
        }

        &.hover-indicator-row:hover,
        &.link-row:focus {
            background-color: $maritech-base-darker;
        }

        td {
            color: $font-colour-primary;
            font-size: $font-size-body-1;
            line-height: 1.55;
            padding: $guttering-small;
            vertical-align: top;

            &.centre-text {
                text-align: center;
            }
        }
    }

    tbody > a.link-row {
        color: inherit;
        display: table-row;
        outline: none;
        text-decoration: none;
    }

    span.decimal-text {
        font-size: 11px;
    }
}

.ops-coa-summary-item:not(first-of-type) {
    padding-top: $guttering-micro;
}

.ops-coa-summary-title {
    color: $font-colour-secondary;
    font-family: $font-family-label;
    font-size: $font-size-body-2;
}

.ops-coa-summary-text {
    color: $font-colour-primary;
    font-family: $font-family-label;
    font-size: $font-size-body-2;
    font-weight: 500;
}
