/* stylelint-disable */
@import './overrides';

@import 'primeflex/src/_variables.scss';
@import 'primeflex/src/_grid.scss';
@import 'primeflex/src/_formlayout.scss';
@import 'primeflex/src/_display.scss';
@import 'primeflex/src/_text.scss';
@import 'primeflex/src/flexbox/_flexbox.scss';
@import 'primeflex/src/_spacing.scss';
@import 'primeflex/src/_elevation.scss';
