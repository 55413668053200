@import 'design-system';

.p-side-panel {
    display: flex;
    flex-direction: column;
    height: 100%;

    .p-side-panel-title {
        border-bottom: solid $maritech-base-darkest $border-width;
        color: $font-colour-primary;
        font-size: $font-size-title;
        font-weight: 500;
        height: $button-height;
        line-height: 1;
        padding: $guttering-small;
    }

    .p-scrollable-content {
        flex-grow: 1;
        height: 100%;
        overflow-y: auto;
    }
}
