$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

$zindex-combined-dropdown: 1100 !default;

$zindex-sea-header: 1240 !default;

$zindex-datepicker: 1300 !default;
