@import 'design-system';

.p-selectbutton {
    display: flex;
}

.p-selectbutton .p-button {
    background-color: $font-color-selected;
    border: solid 1px $maritech-action;
    box-shadow: none;
    color: $maritech-action;
    flex-shrink: 0;
    font-weight: 500;
    height: 30px;
    padding: 0 $guttering-small-medium;
}

.p-selectbutton .p-button.p-highlight {
    background-color: $maritech-action;
    color: $font-color-selected;
}
