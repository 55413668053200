@font-face {
    //  HelveticaNeueW01-55Roma
    font-family: 'HelveticaNeue';
    font-weight: 300;
    src: url('/assets/fonts//helvetica-neue/HelveticaNeue-light.eot?iefix') format('eot');
}

@font-face {
    // HelveticaNeueW01-55Roma
    font-family: 'HelveticaNeue';
    font-weight: 300;
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue-light.eot?iefix');
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue-light.woff') format('woff'), url('/assets/fonts/helvetica-neue/HelveticaNeue-light.ttf');
}

@font-face {
    // font-family: 'HelveticaNeueW01-65Medi';
    font-family: 'HelveticaNeue';
    font-weight: 400;
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue-regular.eot?iefix') format('eot');
}

@font-face {
    // font-family: 'HelveticaNeueW01-65Medi';
    font-family: 'HelveticaNeue';
    font-weight: 400;
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue-regular.eot?iefix');
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue-regular.woff') format('woff'), url('/assets/fonts/helvetica-neue/HelveticaNeue-regular.ttf') format('truetype');
}

@font-face {
    //'HelveticaNeueW01-75Bold'
    font-family: 'HelveticaNeue';
    font-weight: 600;
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue-medium.eot?iefix') format('eot');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-weight: 600;
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue-medium.eot?iefix');
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue-medium.woff') format('woff'),url('/assets/fonts/helvetica-neue/HelveticaNeue-medium.ttf') format('truetype');
}

@font-face {
    // font-family: 'HelveticaNeueW01-85Heav';
    font-family: 'HelveticaNeue';
    font-weight: 800;
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue-bold.eot?iefix') format('eot');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-weight: 800;
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue-bold.eot?iefix');
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue-bold.woff') format('woff'),url('/assets/fonts/helvetica-neue/HelveticaNeue-bold.ttf') format('truetype');
}

@font-face {
    // font-family: 'HelveticaNeueW01-95Blac';
    font-family: 'HelveticaNeue';
    font-weight: 900;
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue-black.eot?iefix') format('eot');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-weight: 900;
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue-black.eot?iefix');
    src: url('/assets/fonts/helvetica-neue/HelveticaNeue-black.woff') format('woff'),url('/assets/fonts/helvetica-neue/HelveticaNeue-black.ttf') format('truetype');
}
