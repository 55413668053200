// Additions to the core classes (not theming)
@import 'design-system';

.p-inputswitch-labelled {
    label {
        display: block;
        padding-bottom: $guttering-tiny;
    }
}

.p-inputswitch-labelled--right {
    .p-inputswitch {
        float: right;
    }
}
