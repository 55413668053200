// Maritech / Ops theme for primeng
@import 'design-system';

@import 'button';
@import 'dialog';
@import 'inputswitch';
@import 'selectbutton';
@import 'checkbox';
@import 'side-panel';

// Base
.p-component {
    font-family: $maritech-font-family;
    font-size: $font-size-body-2;
    font-weight: normal;
}

.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.32);
    transition-duration: 0.2s;
}

.p-disabled,
.p-component:disabled {
    opacity: 0.38;
}

.p-error,
.p-invalid {
    color: $maritech-warning;
}

// Input
.p-field {
    line-height: 1;
    margin-bottom: 0;
    padding: $guttering-small;

    label {
        color: $font-colour-secondary;
        font-size: $font-size-caption;
        margin-bottom: $guttering-tiny;
    }
}

.p-inputtext {
    @extend %input-text;
    width: 100%;

    &.ng-invalid.ng-touched,
    .ngrx-form-group-touched &.ngrx-forms-invalid,
    &.ngrx-forms-invalid.ngrx-forms-touched {
        border-color: $maritech-warn;
    }
}

.p-inputtextarea {
    height: auto;
}

.p-field-checkbox {
    align-items: baseline;
    align-self: flex-end;
    margin-bottom: (-$guttering-tiny);

    label {
        cursor: pointer;
        margin-left: $guttering-medium;
        user-select: none;
    }
}
