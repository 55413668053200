@import 'design-system';

.p-dialog {
    border: 0 none;
    border-radius: 4px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-dialog .p-dialog-header {
    background: #fff;
    border-bottom: 0 none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #000001;
    padding: 1.5rem;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-family: $font-family-title;
    font-size: $font-size-title;
    font-weight: 500;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    background: transparent;
    border: 0 none;
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.6);
    height: 2.5rem;
    margin-right: 0.5rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    width: 2.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon .pi-times::before {
    content: "";
    font-family: 'ClarksonsIconFont';
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    vertical-align: middle;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background: rgba(0, 0, 0, 0.04);
    border-color: transparent;
    color: rgba(0, 0, 0, 0.6);
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: none;
    outline: 0 none;
    outline-offset: 0;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
}

.p-dialog .p-dialog-content {
    background: #fff;
    color: #000001;
    padding: 0 1.5rem 1.5rem;
}

.p-dialog .p-dialog-footer {
    background: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 0 none;
    color: #000001;
    padding: 1rem;
    text-align: right;
}

.p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 0;
}

.p-dialog-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.32);
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-reject {
    @extend %flat-button;
    box-shadow: none !important;
    margin-bottom: 0;
    margin-top: 0;
}
