.ops-scrollable-container-wrapper {
    flex: auto;
    position: relative;

    .ops-scrollable-container {
        display: flex;
        height: 100%;
        position: absolute;
        width: 100%;

        .ops-scrollable-content {
            flex: auto;
            overflow-y: auto;
        }
    }
}
