@import 'design-system';

.p-button {
    @include action-button(false);
}

.p-link-button {
    background-color: transparent;
    border: transparent;
    color: $maritech-action;
    font-size: $font-size-body-1;
    font-weight: bold;
    height: $button-height;
    line-height: $font-size-body-1;
    margin: 0 $guttering-small;
    padding: 0 $guttering-small;

    &:hover {
        text-decoration: underline;
    }

    &:focus {
        box-shadow: none;
        outline: none;
    }

    &:disabled {
        opacity: 0.3;
    }
}
