@import 'clarksons-form';
@import 'design-system';
@import 'zindex';

// Global style to apply to tables for grid layout that falls back to tables
//
// Grid is much more suited to this kind of layout due to the difficulties in positioning within a table cell
//
.ops-form-grid {
    border-bottom: 1px solid $nested-table-heading-background;
    display: grid;

    thead,
    tbody,
    tfoot,
    tr {
        display: contents;
    }

    // Override known components
    ops-cellEditor {
        line-height: 1 !important;

        input {
            background: transparent !important;
            border: 0 !important;
            height: 100% !important;
            min-height: 100% !important;
        }

        ops-quantity-input {
            height: 100%;

            .quantity-input-wrapper {
                height: 100%;

                .quantity-dropdown {
                    background-color: $maritech-base-dark;
                    height: 100%;

                    ng-select {
                        height: 100% !important;

                        div.ng-select-container,
                        div.ng-option {
                            background-color: $maritech-base-dark;
                        }

                        div.ng-select-container {
                            height: 100% !important;
                        }
                    }
                }
            }
        }

        ops-cargo-autosuggest {
            width: 100%;
        }
    }

    ops-date-input,
    ng-select .ng-select-container,
    .ops-select {
        background: transparent !important;
        border: 0 !important;
        height: ($grid-row-height - 2) !important;
        min-height: ($grid-row-height - 2) !important;
    }

    ng-select .ng-clear-wrapper {
        text-align: right;
    }

    .ops-fg-subgrid {
        overflow: visible;

        th {
            vertical-align: middle;
            white-space: pre-wrap;
        }

        > tbody > tr:nth-child(even):not(.ops-fg-editable-row) > td {
            background-color: $section-background;
        }
    }

    > tbody > tr:nth-child(even):not(.ops-fg-editable-row) > td:not(.ops-fg-subgrid) {
        background-color: $section-background;
    }

    th,
    tfoot td {
        align-items: center;
        background-color: $nested-table-heading-background;
        color: $font-colour-primary;
        display: flex;
        font-size: $font-size-body-2;
        font-weight: 500;
        justify-content: start;
        min-height: $grid-row-height;
        padding: 0 $guttering-small;

        &.text-right {
            justify-content: flex-end;
        }
    }

    tfoot td {
        border: 1px solid transparent;
    }

    > tbody > tr {
        > td {
            border: 1px solid transparent;
            display: flex;
            font-size: $font-size-body-1;
            margin-right: -1px; // Enables 1px width borders
            min-height: $grid-row-height;
            padding: 0;
            text-overflow: ellipsis;
            transition: background-color ease-in-out 300ms, border-color ease-in-out 150ms;
            white-space: nowrap;
            will-change: background-color, border-color;

            &:first-child {
                border-left-color: $nested-table-heading-background;
            }

            &:last-child {
                border-right-color: $nested-table-heading-background;
                margin-right: 0;
            }

            .ops-fg-cell-value {
                align-items: center;
                display: flex;
                height: 100%;
                overflow: hidden;
                padding: 0 $guttering-small;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &.ops-fg-cell-readonly {
                > span {
                    flex-grow: 1;
                }
            }

            > ops-cellEditor {
                align-items: center;
                display: flex;
                flex-grow: 1;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                > * {
                    flex-grow: 1;
                }

                > input {
                    padding: 0 $guttering-small;
                }

                > ops-refdata-dropdown {
                    max-width: 100%;
                }
            }

            &.text-right {
                justify-content: flex-end;
            }

            &.text-right * {
                justify-content: flex-end;
                text-align: right;
            }

        }

        .ops-fg-cell-editing {
            > ops-cellEditor {
                overflow: visible;
            }

            &:not(.ops-fg-action-cell) {
                background-color: $input-field-background !important;
            }

            &.ops-fg-cell-focused,
            &:focus-within:not(.ops-fg-action-cell):not(.fixture-tab-cell.ops-fg-cell-invalid) {
                border-color: $maritech-action !important;

                + td {
                    border-left-color: $maritech-action !important;
                }
            }

            // Ensures that the right border doesn't go missing due to the margin offset
            &:not(.ops-fg-cell-readonly) + .ops-fg-action-cell,
            &:not(.ops-fg-cell-readonly) + .ops-fg-cell-readonly {
                border-left-color: $form-control-border-color;
            }
        }

        .ops-fg-cell-invalid {
            border-color: $maritech-warn !important;

            + td {
                border-left-color: $maritech-warn !important;
            }
        }

        .ops-warning--active {
            border-color: $maritech-caution !important;

            + td {
                border-left-color: $maritech-caution !important;
            }
        }
    }

    > tbody > tr.ops-fg-row-editing,
    &:not(.ops-fg-readonly) > tbody > tr:hover {
        > td:not(.ops-fg-subgrid):not(.ops-fg-action-cell):not(.ops-fg-cell-readonly):not(.ops-fg-cell-focused):not(.ops-fg-no-content-cell):not(:focus-within) {
            background-color: $input-field-background;
            border-color: $form-control-border-color;

            + .ops-fg-action-cell,
            + .ops-fg-cell-readonly {
                border-left-color: $form-control-border-color;
            }
        }
    }

    .ops-fg-add-row {
        align-items: center;
        background: transparent;
        border-left: 1px solid $nested-table-heading-background;
        border-right: 1px solid $nested-table-heading-background;
        color: $font-color-secondary;
        cursor: pointer;
        display: flex;
        font-size: $font-size-body-1;
        font-weight: normal;
        justify-content: start;
        min-height: $grid-row-height;
        outline: 0;
        padding: 0 $guttering-small;

        &:hover {
            background-color: $section-background;
            text-decoration: none;
        }

        &:not(:hover):focus {
            outline: 1px solid $maritech-action;
            outline-offset: -1px;
        }
    }
}

// To be used along with .ops-form-grid
.ops-ltc-form-grid {
    thead {
        th {
            background-color: $maritech-base-dark;
            border: 0;
            color: $maritech-grey;
            font-size: $font-size-body-1;
            font-weight: 500;
            line-height: 0.8;
        }
    }

    tbody > tr {
        td {
            background-color: $font-color-selected;
            transition: background-color 0.3s ease;
        }

        &:nth-child(even):not(.ops-fg-editable-row) > td:not(.ops-fg-subgrid) {
            background-color: $nested-table-heading-background;
        }
    }

    span {
        &.decimal-text {
            font-size: 11px;
        }

        &.value {
            padding-right: $guttering-tiny;
        }

        &.ops-fg-cell-value.text-right {
            display: flex;
            justify-content: flex-end;
        }
    }
}
