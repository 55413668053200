/// @deprecated
/// DO NOT EXTEND THIS FILE, MOVE TO PRIMENG COMPONENTS / GLOBAL THEME

@import 'design-system';

.popover {
    border: 0;
    border-radius: $guttering-tiny;
    margin: 0;

    .arrow {
        display: none;
    }

    .popover-body {
        background-color: $maritech-base-lightest;
        border-radius: $guttering-tiny;
        padding: 0;

        .display {
            color: $font-colour-primary;
            font-size: $font-size-body-1;
            padding: $guttering-small;

            .content {
                padding: $guttering-tiny 0 $guttering-tiny $guttering-small-medium;
            }
        }
    }
}
