$maritech-inputswitch-slider: #d9d9d9;
$maritech-inputswitch-toggle: #7d7d7d;
$maritech-inputswitch-slider-checked: #90caf9;
$maritech-inputswitch-toggle-checked: $maritech-action;

.p-inputswitch {
    height: 1rem;
    width: 2.75rem;
}

.p-inputswitch .p-inputswitch-slider {
    background: $maritech-inputswitch-slider;
    border-radius: 0.5rem;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.p-inputswitch .p-inputswitch-slider::before {
    background: $maritech-inputswitch-toggle;
    border-radius: 50%;
    height: 1.5rem;
    left: -1px;
    margin-top: -0.75rem;
    transition-duration: 0.2s;
    width: 1.5rem;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before {
    transform: translateX(1.5rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: none;
    outline: 0 none;
    outline-offset: 0;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: $maritech-inputswitch-slider-checked;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before {
    background: $maritech-inputswitch-toggle-checked;
}

.p-inputswitch .p-inputswitch-slider::before {
    box-shadow:
        0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition-property: box-shadow, transform;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider::before {
    box-shadow:
        0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14),0
        0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 0 1px 10px rgba(0, 0, 0, 0.04);
}

.p-inputswitch.p-inputswitch-focus .p-inputswitch-slider::before,
.p-inputswitch.p-inputswitch-focus:not(.p-disabled):hover .p-inputswitch-slider::before {
    box-shadow:
        0 0 1px 10px rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider::before {
    box-shadow:
        0 0 1px 10px rgba(103, 58, 183, 0.04),
        0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider::before,
.p-inputswitch.p-inputswitch-checked.p-inputswitch-focus:not(.p-disabled):hover .p-inputswitch-slider::before {
    box-shadow:
        0 0 1px 10px rgba(103, 58, 183, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

// TODO: InputSwitch colors from design for disabled/hover states
//.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
//    background: rgba(255, 255, 255, 0.3);
//}
//.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
//    background: rgba(206, 147, 216, 0.5);
//}
//.p-inputswitch.p-error, .p-inputswitch.p-invalid {
//    border-color: #f44435;
//}
//
//p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
//    border-color: #f44435;
//}
