@import 'bootstrap/scss/bootstrap';

/* Angular Material requires importing a prebuilt theme - styles referenced by MatDialog */
@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import 'design-system';
@import 'clarksons-breakpoints';
@import 'table';
@import 'clarksons-datepicker';
@import 'form-grid';
@import 'clarksons-popover';
@import 'coa';
@import 'ltc';
@import 'scrollable-container';

@import 'assets/styles/primeng/inputswitch.extensions';
@import 'assets/styles/primeflex/primeflex';
@import 'assets/styles/primeng/theme';

/* You can add global styles to this file, and also import other style files */
body,
html {
    background-color: $maritech-base;
    font-family: $maritech-font-family;
    font-size: $font-size-body-2;
    height: 100%;
}

.flex-spacer {
    flex-grow: 1;
}

.ag-has-focus .ag-cell-focus {
    border: 0 none !important;
}

// NgbPopover has a very arbitrary max-width of 276px which is too narrow for some of our menus. This will allow any
// NgbPopovers to grow to fit any content size we like.
.popover {
    max-width: 100%;
}

// TODO: Write a better loading overlay control or move this
.loading-panel-container {
    position: relative;

    .loading-panel {
        background-color: rgba(0, 0, 0, 0.3);
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 1;

        .loading-animation {
            left: 50%;
            position: absolute;
            top: 50%;
        }
    }
}

.cdk-overlay-container {
    z-index: $zindex-fixed;
}

.cdk-overlay-backdrop {
    z-index: $zindex-modal-backdrop;
}

.cdk-overlay-pane {
    max-width: 95vw !important;
    max-height: calc(100vh - 100px) !important;
}

.cdk-global-overlay-wrapper {
    padding-top: 97px;
    z-index: $zindex-modal;
}

body > .ng-dropdown-panel {
    border: 0 none;
    border-radius: 0 !important;
    border-top: $border-width solid $maritech-base-darker;
    box-shadow: 0 $guttering-small $guttering-small 0 rgba(0, 0, 0, 0.5);
    min-width: 100px;
    z-index: $zindex-combined-dropdown;

    .ng-dropdown-panel-items {
        .ng-option {
            background-color: $maritech-base-dark;
            border: $border-width-medium solid transparent;
            border-radius: 0 !important;
            font-size: $font-size-body-1;
            padding: 10px ($guttering-small - ($border-width-medium - $border-width)); // offset to match input border inset

            &:active,
            &.ng-option-selected {
                background-color: $maritech-blue-darkest;
                border-color: transparent-color($maritech-white, 50%);
                color: #fff;
            }

            &.ng-option-marked:not(:active) {
                background-color: transparent-color($maritech-action, 30%);
                border-color: transparent;
                color: $font-colour-primary;
            }
        }
    }
}

.mat-dialog-container {
    padding: 0 !important;
}

.left-toolbar-expander {
    color: $font-color-primary;
    cursor: pointer;
    float: right;
    margin-bottom: $guttering-small;
    margin-left: $guttering-small;
    margin-right: -$guttering-small;
    margin-top: -$guttering-small;
    padding: 6px;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-reject:first-child, .p-button:first-child {
    margin-bottom: 0;
}

.p-datatable > .p-datatable-wrapper {
    overflow: inherit !important;
}