// TODO: Move to ops- or mt- prefix

@import 'design-system';
@import 'clarksons-action-button';
@import 'clarksons-form';

$table-row-height: $grid-row-height; // 32px
$table-row-height-spaced: 40px;

.table {
    thead {
        font-weight: 500;

        tr {
            th {
                .required::before {
                    color: $maritech-caution;
                    content: '* ';
                }

                &.extra-small-size {
                    width: 2%;
                }

                &.small-size {
                    width: 4%;
                }

                &.medium-small-size {
                    width: 6%;
                }

                &.medium-size {
                    width: 8%;
                }

                &.medium-large-size {
                    width: 10%;
                }

                &.large-size {
                    width: 12%;
                }

                &.extra-large-size {
                    width: 20%;
                }
            }
        }
    }

    tbody + tbody {
        border-top-color: $nested-sub-heading-background;
    }

    tr {
        td,
        th {
            border: 0 none;
            font-size: $font-size-body-1;
            font-weight: inherit;
            height: $table-row-height;
            line-height: 1.25;
            padding: $guttering-small;
            vertical-align: middle;

            @extend %ops-action-button;
        }

        td.input-cell {
            padding: 0;
        }

        &.form-group {
            ::ng-deep {
                .ops-select.ng-select.form-control {
                    .ng-select-container {
                        height: $input-element-height-condensed;
                        min-height: $input-element-height-condensed;
                    }
                }
            }

            td > .form-control {
                border-radius: 0;
                box-shadow: none;
                color: $font-colour-primary;
                font-size: $font-size-body-1;
                height: $grid-row-height;
                min-height: $grid-row-height;

                &.ng-invalid.ng-touched,
                &.ngrx-forms-invalid.ngrx-forms-touched {
                    border-color: $maritech-warn;
                }

                &:focus-within:not(.ng-invalid):not(.ngrx-forms-invalid) {
                    border: $border-width solid $maritech-action;
                }
            }

            &.disabled {
                .form-control {
                    background-color: $form-control-disabled-background;
                    opacity: 1;
                }
            }
        }
    }

    &.table-spaced {
        tr {
            td,
            th {
                height: $table-row-height-spaced;
                padding: $guttering-small-medium;
            }

            th {
                font-size: $font-size-body-2;
            }
        }
    }

    &.table-striped {
        border: 1px solid $nested-table-heading-background;
        color: $font-colour-primary;

        thead,
        tfoot {
            tr {
                background-color: $nested-table-heading-background;
            }
        }

        th,
        thead td,
        tfoot td {
            font-weight: 500;
        }

        tbody:nth-child(odd) tr:nth-child(odd),
        tbody:nth-child(even) tr:nth-child(even) {
            background-color: $section-background;
        }

        tbody:nth-child(odd) tr:nth-child(even),
        tbody:nth-child(even) tr:nth-child(odd) {
            background-color: $maritech-base;
        }
    }

    &.table-nested {
        thead {
            th {
                padding: 0 $guttering-small;
                vertical-align: middle;
            }
        }

        tbody {
            td {
                border: 0;
                font-size: $font-size-body-2;
            }
        }
    }
}

:host-context(.invalid-on-save) {
    .form-control {
        &.ng-invalid {
            border-color: $maritech-warn;
        }
    }
}

.table-empty {
    padding: $guttering-medium;

    .empty-icon {
        color: $font-colour-secondary;
        line-height: 1.25;
        padding-right: $guttering-small;
    }

    .empty-title {
        color: $font-colour-primary;
    }

    .empty-description {
        color: $font-colour-secondary;
        font-size: $font-size-body-2;
        margin-bottom: 0;
    }

    @extend %ops-action-button;
}
